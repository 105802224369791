<template>
<div>
  <div class="container-fluid">
    <PageTitle :noAdd="false" />
  
    <div 
      v-if="isList" 
      class="card"
    >
      <div class="card-header">
        <h5 class="card-title">{{pageTitle}} List</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="card-body" v-if="data.data.length > 0 && data.data ">
            <div class="row">
              <div class="col-md-8">
              </div>
              <div class="col-md-4">
                <form @submit.prevent="doFilter(),doSearch()">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input v-model="filter.search" type="text" class="form-control" @input="checkDisabled"
                        placeholder="Type and Press Enter" required="" />
                      <div class="input-group-append">
                        <button disabled="disabled" class="btn btn-info" id="btn-search" type="submit"><i class="fas fa-search"></i></button>
                      </div>
                        <button v-if="isSearch" @click="doReset(),clsSearch()" type="button" class="btn btn-danger ml-1"><i class="fa fa-times"></i>
                        </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-12">
            <draggable v-bind="dragOptions" class="row justify-content-start" v-model="data.data" tag="tbody"   @start="startDrag" @end="endDrag">
              <div class="col-lg-3 col-md-6" v-for="(v,k) in data.data" :key="k" style="cursor: move;">
                <div class="card" v-tooltip="v.ac_update_date ? 'Last updated : ' + v.ac_update_date.dates('format2') :  'Last updated : ' + v.ac_create_date.dates('format2')">
                  <div class="card-body">
                    <div class="product-img d-flex align-items-center text-center justify-content-center" style="position:relative;">
                      <img 
                        :src="v.ac_logo ? apiUrl+'/uploader/'+(v.ac_logo || '').removeWebp() : baseUrl+'/img/no-image.jpg'" alt=""
                      >
                      <div class="bullet-cta">
                        <router-link
                          :to="{name:$route.name, params:{pageSlug:v[idKey]}}"  
                          class="bullet_edit bg-info finger" role="button" v-tooltip="'Edit'"
                         >
                          <i class="ti-marker-alt" style="color: #fff;"></i>
                        </router-link>
                        
                        <a 
                          class="bullet_edit ml-2 finger" v-tooltip="'Change Status'"
                          @click.prevent="doChangeStatus(k,v)"
                        >
                          <i class="ti-settings"></i>
                        </a>

                        <a 
                          @click.prevent="doDelete(v.ac_id, v)" 
                          class="bullet_delete ml-2 finger" style="cursor:pointer;"
                           v-tooltip="'Delete'"
                        ><i class="far fa-trash-alt"></i></a>
                      </div>
                    </div>

                    <div class="product-text mt-3 text-center">
                      <h4>{{ v.ac_name }}</h4>
                      <span 
                        class="label"
                        :class="v.ac_is_active === 'Y' ?'label-success' : 'label-danger'"
                      >{{ v.ac_is_active === "Y" ? "Active" : "Inactive" }}</span>
                    </div>

                  </div>
                </div>
              </div>
            </draggable>
          </div>
  
          <div class="col-lg-12 text-center" v-if="data.data.length === 0 && !isSearch">
            <h4 class="mt-4">Clients Data Not Available</h4>
          </div>

          <div class="card-body" v-if="data.data.length === 0 && isSearch">
            <div class="row">
              <div class="col-md-8">
              </div>
              <div class="col-md-4">
                <form @submit.prevent="doFilter(),doSearch()">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input v-model="filter.search" type="text" class="form-control" @input="checkDisabled"
                        placeholder="Type and Press Enter" required="" />
                      <div class="input-group-append">
                        <button disabled="disabled" class="btn btn-info" id="btn-search" type="submit"><i class="fas fa-search"></i></button>
                      </div>
                        <button v-if="isSearch" @click="doReset(),clsSearch()" type="button" class="btn btn-danger ml-1"><i class="fa fa-times"></i>
                        </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 text-center">
                <h4 class="mt-4">Clients Not Found</h4>
              </div>
            </div>
          </div>


          <div class="col-lg-12 d-flex justify-content-center" v-if="data.data === false">
            <Loader class="mt10 mb10" />
          </div>
        </div>
      </div>
      <div class="card-footer" v-if="data.last_page > 1">
        <div class="row">
          <div class="col-sm-6"></div>
          <div class="col-sm-6">
            <div class="pagination-flat float-right" v-if="data.data!==false&&data.data.length">
              <Pagination :data="data" :limit="2" @pagination-change-page="doPaginate">
                <span slot="prev-nav"><i class="icon-arrow-left"></i></span>
                <span slot="next-nav"><i class="icon-arrow-right"></i></span>
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd?'Add':'Edit'}} {{pageTitle}}</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <div class="row">
              <div class="col-md-9 mt-3">
                <div class="row">
                  <div class="col-md-9">
                    <div class="form-group">
                      <label class="control-label">
                        Client Name 
                        <span class="text-danger mr5">*</span>
                      </label>
                      <input 
                        v-model="row.ac_name"
                        type="text" 
                        class="form-control" 
                        placeholder="Client Name" 
                      /> 
                      <VValidate name="Client Name" v-model="row.ac_name" :rules="mrValidation.ac_name" />
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="form-group">
                      <label class="control-label">
                        Link
                      </label>

                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="basic-addon1"><i class="fas fa-link"></i></span>
                        </div>
                        <input 
                          type="text" 
                          class="form-control" 
                          placeholder="Link" 
                          v-model="row.ac_link">
                      </div>
                    </div>
                  </div>

                  <div class="col-md-9">
                    <label class="control-label">Status <span class="text-danger mr5">*</span> </label>
                    <div>
                      <InputRadio v-model="row[statusKey]" name="status" option="Y" />
                      <InputRadio v-model="row[statusKey]" name="status" option="N" />
                    </div>
                    <VValidate name="Status" v-model="row[statusKey]" :rules="mrValidation[statusKey]" />
                    <hr>
                  </div>
                </div>
  
              </div>

              <div class="col-md-3 mt-3">
                <div class="form-group">
                  <label class="control-label">
                    Logo
                    <span class="text-danger mr5">*</span>
                  </label>
                  <Uploader v-model="row.ac_logo" type="client" label="Client Logo"/>
                  <VValidate name="Logo" v-model="row.ac_logo" rules="required" />
                  <div class="form-group mt-3">
                    <label class="control-label">Alt Image <span class="text-danger">*</span></label>
                    <input type="text" v-model="row.ac_alt" class="form-control" placeholder="Alt Image">
                    <VValidate name="Alt Image Logo" v-model="row.ac_alt" rules="required" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                <button type="submit" class="btn btn-block btn-rounded btn-info">Submit</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>

  </div>
</div>
</template>
<style type="text/css" scoped="">
  .wi, [class*=" ti-"], [class^=ti-] {
    font-style: normal;
    font-weight: 400;
    line-height: 2.2 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button:disabled {
  cursor: auto;
}
</style>
<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import draggable from 'vuedraggable'
import Gen from '@libs/Gen'

let $ = global.jQuery

export default{
  extends:GlobalVue,
  components:{PageTitle,draggable},
  data(){
    return {
      idKey:'ac_id',
      statusKey:'ac_is_active',
      isOverlayImg: false,
      idOverlayImg: 1,
      isSearch : false,
      status: ""
    }
  },
  mounted(){
    this.apiGet()

    this.filter = {
      search: ""
    }
    setTimeout(() => {
      if(this.filter.search.length <= 0){
        $('#btn-search').attr("disabled", "disabled")
      } else {
        $('#btn-search').removeAttr("disabled")
      }
    }, 300)
    
  },
  methods:{
    startDrag(){
      $(".sortable-fallback").removeAttr("style")
      $(".sortable-fallback").css("display","none")
    },
    endDrag(){
      const page = this.$route.query.page || 1
      Gen.apiRest(
        "/do/"+this.modulePage,
        {data: {type: 'sort', data: this.data.data, page: page}},
        "POST"
      )
    },
    doSearch (){
        this.isSearch = true
    },
    clsSearch(){
        this.isSearch = false
    },
    checkDisabled(){
      if(this.filter.search.length <= 0) {
        $('#btn-search').attr("disabled", "disabled")
      } else {
        $('#btn-search').removeAttr("disabled")
      }
    }
  },
  watch:{
    $route(){
      this.apiGet()


      setTimeout(() => {
        if(!this.isList){
          this.filter = {}
          this.isSearch = false
        } else {
          if(this.filter.search.length <= 0){
            $('#btn-search').attr("disabled", "disabled")
          } else {
            $('#btn-search').removeAttr("disabled")
          }
        }
        
      }, 300)
      

    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    },
    'row.ac_name'(v){
      this.row.ac_alt = v
    },
  }
}
</script>